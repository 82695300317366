<template>
    <div :class="`m-3 p-2 wrapper  wrapper--default`">
        <h3 class="text-center">Transport Lijst</h3>
        <Loading v-if="loading" />
        <div v-if="transport_list">
        <Tabs>
            <Tab v-for="(transporteur, key) in transport_list" :key="key" :title="`${key}`">
                <table :class="`w-100 table--default table__border--default mt-1`">
                    <thead :class="`table__head--default`">
                        <tr>
                            <th class="table__head--text">Item</th>
                            <th class="table__head--text">Voertuig</th>
                            <th class="table__head--text" style="min-width: 100px">
                                Verwacht
                            </th>
                            <th class="table__head--text">Betaald</th>
                            <th class="table__head--text">Bedrijf</th>
                            <th class="table__head--text">Plaats</th>
                            <th class="table__head--text">Land</th>
                            <th class="table__head--text">Kosten</th>
                            <th class="table__head--text">Opdrachtgever</th>
                            <th class="table__head--text">Opmerking</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, key) in transporteur" :key="key" class="table__row--color">
                            <td class="table__cell--default">
                                <ImageHover :itemnummer="item.Itemnummer" :bu="bu" />
                            </td>
                            <td class="table__cell--default">{{ item.Voertuig }}</td>
                            <td class="table__cell--default">{{ item.Levering }}</td>
                            <td class="table__cell--default">
                                <div v-if="item.openstaand == 0">
                                    <span v-b-tooltip.hover :title="item.betaaldatum"><i
                                            class="fas fa-euro-sign"></i></span>
                                </div>
                                <div v-else>
                                    <span v-b-tooltip.hover title="Nog niet betaald"><i
                                            class="fab fa-creative-commons-nc-eu"></i></span>
                                </div>
                            </td>
                            <td class="table__cell--default">{{ item.Bedrijf }}</td>
                            <td class="table__cell--default">{{ item.plaats }}</td>
                            <td class="table__cell--default">{{ item.Land }}</td>
                            <td class="table__cell--default">
                                €{{ item.Kosten }}
                            </td>
                            <td class="table__cell--default">
                                {{ item.Opdrachtgever }}
                            </td>
                            <td class="table__data--right table__cell--default">
                                {{ item.opmerkingen }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Tab>
        </Tabs>
    </div>
    </div>
</template>

<script>
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";
import request from "@/functions/request.js";
import Loading from "@/components/Loading";
import ImageHover from "@/components/ImageHover.vue";

export default {
    components: { Tabs, Tab, Loading, ImageHover },
    data: () => ({
        transport_list: null,
        loading: null,
    }),
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true;
            request(`transport-list`, 'GET').then(({ transport_list }) => {
                this.transport_list = transport_list;
                this.loading = false;
            })
        }

    }

}

</script>